import 'mdn-polyfills/NodeList.prototype.forEach';

export default class Header {
  constructor(element) {
    this.element = element;
    this.hasScrolled = true;

    this.updateScroll = this.updateScroll.bind(this);
  }


  updateScroll() {

    var sticky = window.innerHeight;

    if (window.pageYOffset > sticky) {
      this.element.classList.add("c-header--visible");
    } else {
      this.element.classList.remove("c-header--visible");
    }
  }


  mount() {

    this.height = this.element.offsetHeight;
    document.documentElement.style.setProperty('--vh', `${window.innerHeight/100}px`);

    window.addEventListener('scroll', this.updateScroll);

    const loop = () => {
      if (this.hasScrolled) {
        this.updateScroll();
        this.hasScrolled = false;
      }

      requestAnimationFrame(loop);
    }

    loop();

    this.element.classList.add('is-mounted');
  }

  unmount() {
    window.removeEventListener('scroll', this.handleScroll);
    this.element.classList.remove('is-mounted');
  }
}
